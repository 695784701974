














































































import {Component, Vue} from 'vue-property-decorator'
import draggable from 'vuedraggable/dist/vuedraggable.umd.js'
import {ShopItem, ShopItemStatus, ShopItemType} from '~/components/data-class/data-class'
import {createRequest} from '~/utils/network-request'
import {isEmptyString} from '~/utils/misc'
import {ShopPermission} from '~/utils/permissions'

@Component({
    components: {
        draggable
    },
    metaInfo() {
        return {
            title: 'Shop Item'
        }
    }
})
export default class ShopItemDetail extends Vue {
    static permission = ShopPermission.EditShopItems

    item: ShopItem = new ShopItem()
    imgLoading = false
    fileList = [] // not using
    uploadTotal = 0
    uploadCount = 0
    uploadFailed = 0
    async created() {
        const id = this.$route.params['id']
        if (!isEmptyString(id)) {
            const res = await createRequest(`/shop/item/${id}`, 'get').send()
            this.item = res.data.item
        }
    }

    ShopItemType = ShopItemType
    ShopItemStatus = ShopItemStatus

    typeOptions = [
        {label: 'Real', id: ShopItemType.REAL},
        {label: 'Virtual', id: ShopItemType.VIRTUAL}]

    statusOptions = [
        {label: 'In Stock', id: ShopItemStatus.IN_STOCK},
        {label: 'Out of Stock', id: ShopItemStatus.OUT_OF_STOCK},
        {label: 'Hidden', id: ShopItemStatus.HIDDEN},
    ]

    removeImg(index) {
        this.item.image_urls.splice(index, 1)
    }

    get uploadUrl() {
        return `${process.env.VUE_APP_DOMAIN_API}/file`
    }
    async uploadImage(param) {
        const fileObj = param.file
        const form = new FormData()
        form.append('file', fileObj)
        this.uploadTotal++
        this.imgLoading = true

        try {
            const res = await createRequest('/file', 'post', {}, form).send()
            this.item.image_urls.push(res.data.file_url)
        } catch (e) {
            console.log(e)
            this.uploadFailed++
        }

        this.uploadCount++
        if (this.uploadCount >= this.uploadTotal) {
            this.imgLoading = false
            this.uploadTotal = 0
            this.uploadCount = 0
            if (!this.uploadFailed) {
                this.$message({
                    message: 'Uploaded',
                    type: 'success'
                })
            } else {
                this.$message({
                    message: `${this.uploadFailed} upload${this.uploadFailed ? 's' : ''} Failed`,
                    type: 'error'
                })
            }
            this.uploadFailed = 0
        }
    }
    async onSubmit() {
        // this.fileList.pop()
        const i = this.item
        const body = {
            description: i.description,
            image_urls: i.image_urls,
            status: i.status,
            price: i.price,
            expire: i.expire,
            title: i.title,
            short: i.short,
            type: i.type
        }

        const id = this.$route.params['id']
        if (!isEmptyString(id)) {
            await createRequest(`/shop/item/${id}`, 'patch', {}, body).send()
            this.$message({
                message: 'Saved!',
                type: 'success'
            })
        } else {
            await createRequest('/shop/item', 'post', {}, body).send()
            this.$router.push('/shop/items')
        }
    }
}
